import React, { lazy, Suspense } from 'react'

// material-ui
import { styled } from '@mui/material/styles'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'

const Header = lazy(() => import('layout/common-layout/header'))
const FooterBlock = lazy(() => import('layout/common-layout/footer-block'))

// ==============================|| Loader ||============================== //

const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2),
  },
}))

export interface LoaderProps extends LinearProgressProps {
}

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color='primary' />
  </LoaderWrapper>
)

// ==============================|| MINIMAL LAYOUT ||============================== //

type Props = {
  layout?: 'landing' | 'simple' | 'blank'
  children?: React.ReactNode
}

export const CommonLayout: React.FC<Props> = ({ layout = 'blank', children }) => (
  <>
    {(layout === 'landing' || layout === 'simple') && (
      <Suspense fallback={<Loader />}>
        <Header layout={layout} />
        {children}
        <FooterBlock isFull={layout === 'landing'} />
      </Suspense>
    )}
    {layout === 'blank' && children}
  </>
)
