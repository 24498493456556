import * as React from 'react'
import { FormHelperText, InputLabel, Stack, TextField } from '@mui/material'
import { uniqueId } from 'lodash'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { useFormikContext } from 'formik'

type Props = TextFieldProps & {
  field: string
  label?: React.ReactNode
  hint?: React.ReactNode
}

export const SmartTextField: React.FC<Props> = props => {
  const { field, label, hint, ...otherProps } = props
  const { handleChange, getFieldMeta, isSubmitting } = useFormikContext()
  const id = React.useMemo(() => uniqueId(), [])

  const meta = getFieldMeta(field)

  return (
    <Stack spacing={1}>
      {label && <InputLabel htmlFor={`field_${id}`}>{label}</InputLabel>}
      <TextField
        fullWidth
        id={`field_${id}`}
        value={meta.value ?? ''}
        onChange={handleChange}
        error={Boolean(meta.error)}
        helperText={meta.error}
        name={field}
        InputProps={{
          readOnly: isSubmitting,
        }}
        {...otherProps}
      />
      {hint && <FormHelperText>{hint}</FormHelperText>}
    </Stack>
  )
}
