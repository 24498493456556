// action - state management
import { ReduxActions } from './actions'

// types
import { AuthActionProps, AuthProps } from 'types/auth'

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  notificationsCount: 0,
  myTasksCount: 0,
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action: AuthActionProps) => {
  switch (action.type) {
    case ReduxActions.INIT: {
      return {
        ...state,
        isInitialized: true,
      }
    }
    case ReduxActions.REGISTER: {
      const { user } = action.payload!
      return {
        ...state,
        user,
      }
    }
    case ReduxActions.LOGIN: {
      const { user } = action.payload!
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user
      };
    }
    case ReduxActions.LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
      }
    }
    case ReduxActions.SET_NOTIFICATIONS_COUNT: {
      const { notificationsCount } = action.payload!
      return {
        ...state,
        notificationsCount: notificationsCount,
      }
    }
    case ReduxActions.SET_MY_TASKS_COUNT: {
      const { myTasksCount } = action.payload!
      return {
        ...state,
        myTasksCount: myTasksCount,
      }
    }
    default: {
      return { ...state }
    }
  }
};

export default auth;
