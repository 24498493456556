import React, { createContext, useEffect, useReducer } from 'react'

// reducer - state management
import { ReduxActions } from 'store/reducers/actions'
import authReducer from 'store/reducers/auth'

// project import
import Loader from 'components/Loader'
import axios from 'utils/axios'
import { AuthContextType, AuthProps, UserProfile } from 'types/auth'
import { useLazyQuery } from '@apollo/client'
import { MeQuery } from 'contexts/queries'
import { Me } from 'contexts/__generated__/Me'
import { apolloClient } from 'services/apollo'
import { WildCardMatch } from 'utils/wild-card-match'

// constant
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  myTasksCount: 0,
  notificationsCount: 0,
}

const setSession = (token?: string | null) => {
  if (token) {
    localStorage.setItem('token', token)
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    localStorage.removeItem('token')
    delete axios.defaults.headers.common.Authorization
  }
}

const AuthContext = createContext<AuthContextType | null>(null)

export const AuthContextComponent = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)
  // const [permissionsCache, setPermissionsCache] = React.useState<Record<string, boolean>>({})

  let permissionsCache: Record<string, boolean> = {}

  const [getMe, { loading, data, error }] = useLazyQuery<Me>(MeQuery)

  useEffect(() => {
    const serviceToken = window.localStorage.getItem('token')
    if (serviceToken) {
      setSession(serviceToken)
      getMe({ pollInterval: 10000 })
    } else {
      dispatch({ type: ReduxActions.INIT })
    }
  }, [])

  useEffect(() => {
    if (error) {
      console.error(error)
      dispatch({
        type: ReduxActions.LOGOUT,
      })
    }

    if (data) {
      dispatch({
        type: ReduxActions.LOGIN,
        payload: {
          isLoggedIn: true,
          user: {
            id: data.me.user.id,
            name: data.me.user.name,
            permissions: data.me.user.permissions,
            email: data.me.user.email,
          },
        },
      })
      dispatch({
        type: ReduxActions.SET_NOTIFICATIONS_COUNT,
        payload: {
          isLoggedIn: true,
          notificationsCount: data.me.notificationsCount,
        },
      })
      dispatch({
        type: ReduxActions.SET_MY_TASKS_COUNT,
        payload: {
          isLoggedIn: true,
          myTasksCount: data.me.myTasksCount,
        },
      })
    }
  }, [loading])

  useEffect(() => {
    permissionsCache = {}
  }, [data?.me.user.permissions])

  useEffect(() => {
    if (!data) return
    dispatch({
      type: ReduxActions.SET_MY_TASKS_COUNT,
      payload: {
        isLoggedIn: true,
        myTasksCount: data.me.myTasksCount,
      },
    })
  }, [data?.me.myTasksCount])

  useEffect(() => {
    if (!data) return
    dispatch({
      type: ReduxActions.SET_NOTIFICATIONS_COUNT,
      payload: {
        isLoggedIn: true,
        notificationsCount: data.me.notificationsCount,
      },
    })
  }, [data?.me.notificationsCount])

  const login = (token: string, user: UserProfile) => {
    setSession(token)
    dispatch({
      type: ReduxActions.LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    })
  }

  const logout = () => {
    setSession(null)
    apolloClient.resetStore()
    dispatch({ type: ReduxActions.LOGOUT })
  }

  const checkPermission = (permission: string) => {
    if (permission in permissionsCache) {
      return permissionsCache[permission]
    }

    const hasAccess = (!state.user?.permissions?.includes('!'.concat(permission)) && state.user?.permissions?.some(grantedPermission =>
      WildCardMatch(permission, grantedPermission) || WildCardMatch(grantedPermission, permission))) ?? false

    permissionsCache[permission] = hasAccess

    return hasAccess
  }

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />
  }

  return <AuthContext.Provider value={{ ...state, login, logout, checkPermission }}>{children}</AuthContext.Provider>
}

export default AuthContext
