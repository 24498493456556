import React from 'react'

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material'

// third party
import * as Yup from 'yup'
import { Formik, FormikProps } from 'formik'

// project import
import IconButton from 'components/@extended/IconButton'
import AnimateButton from 'components/@extended/AnimateButton'
import { AuthWrapper } from 'sections/auth/auth-wrapper'

// assets
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { LoginMutation } from 'sections/auth/auth-forms/queries'
import { Login, LoginVariables } from 'sections/auth/auth-forms/__generated__/Login'

type Props = {
  proceedTo2FA: (token: string) => void
}

const AuthLogin: React.FC<Props> = ({ proceedTo2FA }) => {
  const [capsWarning, setCapsWarning] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)

  let formik: FormikProps<any> | null = null

  const [loginResult, loginState] = useMutation<Login, LoginVariables>(LoginMutation)

  React.useEffect(() => {
    if (loginState.error?.message) {
      formik?.setErrors({ submit: loginState.error.message })
    }

    if (loginState.data) {
      formik?.setSubmitting(false)
      proceedTo2FA(loginState.data.login.codeToken)
    }
  }, [loginState.loading])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault()
  }

  const onKeyDown = (keyEvent: any) => {
    if (keyEvent.getModifierState('CapsLock')) {
      setCapsWarning(true)
    } else {
      setCapsWarning(false)
    }
  }

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction='row'
                 justifyContent='space-between'
                 alignItems='baseline'
                 sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant='h3'>Login</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Formik
            innerRef={p => {
              formik = p
            }}
            initialValues={{
              email: '',
              password: '',
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required'),
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              loginResult({ variables: { email: values.email, password: values.password } })
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor='email-login'>Email Address</InputLabel>
                      <OutlinedInput
                        id='email-login'
                        type='email'
                        value={values.email}
                        name='email'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder='Enter email address'
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                      />
                      {touched.email && errors.email && (
                        <FormHelperText error id='standard-weight-helper-text-email-login'>
                          {errors.email}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor='password-login'>Password</InputLabel>
                      <OutlinedInput
                        fullWidth
                        color={capsWarning ? 'warning' : 'primary'}
                        error={Boolean(touched.password && errors.password)}
                        id='-password-login'
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        name='password'
                        onBlur={(event: React.FocusEvent<any, Element>) => {
                          setCapsWarning(false)
                          handleBlur(event)
                        }}
                        onKeyDown={onKeyDown}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                              color='secondary'
                            >
                              {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder='Enter password'
                      />
                      {capsWarning && (
                        <Typography variant='caption'
                                    sx={{ color: 'warning.main' }}
                                    id='warning-helper-text-password-login'>
                          Caps lock on!
                        </Typography>
                      )}
                      {touched.password && errors.password && (
                        <FormHelperText error id='standard-weight-helper-text-password-login'>
                          {errors.password}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <AnimateButton>
                      <Button disableElevation
                              disabled={isSubmitting}
                              fullWidth
                              size='large'
                              type='submit'
                              variant='contained'
                              color='primary'>
                        Login
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </AuthWrapper>
  )
}

export default AuthLogin
