import { setContext } from '@apollo/client/link/context'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Application: {
        fields: {
          statusLog: {
            merge: (existing, incoming) => {
              return incoming
            },
          },
        },
      },
      FileRequestFile: {
        fields: {
          uploadedFiles: {
            merge: (existing, incoming) => {
              return incoming
            },
          },
        },
      },
    },
  }),
  link: authLink.concat(httpLink),
})


export { apolloClient }
