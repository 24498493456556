// material-ui
import { Theme } from '@mui/material/styles'
import { Stack, useMediaQuery } from '@mui/material'

// project import
import Profile from './profile'
import Notification from 'layout/main-layout/header/header-content/notification'
import MobileSection from 'layout/main-layout/header/header-content/mobile-section'

import useConfig from 'hooks/useConfig'
import DrawerHeader from 'layout/main-layout/drawer/drawer-header'

// type
import { LAYOUT_CONST } from 'types/config'
import { Search } from 'layout/main-layout/header/header-content/search'

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig()

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  return (
    <>
      {menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG && <DrawerHeader open={true} />}
      {!downLG && <Stack sx={{ width: '100%', ml: { xs: 0, md: 1 } }} direction='column' alignItems='center'>
        <Search />
      </Stack>}
      {downLG && <Stack sx={{ width: '100%', ml: 1 }} direction='column' alignItems='center'><Search /></Stack>}

      <Notification />
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  )
}

export default HeaderContent
