import * as React from 'react'
import { configPage } from 'contexts/layout.context'
import { useMutation, useQuery } from '@apollo/client'
import { DeleteLoanDocumentSectionMutation, GetLoanDocumentSectionsQuery } from 'pages/loan-document-sections/queries'
import { GetLoanDocumentSections } from 'pages/loan-document-sections/__generated__/GetLoanDocumentSections'
import { MainCard } from 'components/MainCard'
import { Button, IconButton, Stack } from '@mui/material'
import Loader from 'components/Loader'
import { HasPermissions } from 'components/permissions/has-permissions'
import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons'
import { ReactTable } from 'components/react-table'
import { ArrayElement } from 'utils/array-element'
import {
  DeleteLoanDocumentSection,
  DeleteLoanDocumentSectionVariables,
} from 'pages/loan-document-sections/__generated__/DeleteLoanDocumentSection'
import {
  CreateLoanDocumentSectionSection,
} from 'pages/loan-document-sections/sections/create-loan-document-section.section'
import {
  UpdateLoanDocumentSectionSection,
} from 'pages/loan-document-sections/sections/update-loan-document-section.section'

export const LoanDocumentSections: React.FC = () => {
  configPage('Loan Document Sections', [{ title: 'Loan Document Sections', link: '/loan-document-sections' }])

  const sections = useQuery<GetLoanDocumentSections>(GetLoanDocumentSectionsQuery)
  const [deleteSection, deleteSectionState] = useMutation<DeleteLoanDocumentSection, DeleteLoanDocumentSectionVariables>(DeleteLoanDocumentSectionMutation)

  const [createModalOpen, setCreateModalOpen] = React.useState(false)
  const [updateSectionItem, setUpdateSectionItem] = React.useState<ArrayElement<GetLoanDocumentSections['loanDocumentSections']>>()

  const deleteHandler = React.useCallback((id: string, name: string) => {
    if (confirm(`You sure you want to remove ${name} section?`)) deleteSection({ variables: { id: id } })
  }, [])

  React.useEffect(() => {
    if (!deleteSectionState.loading) sections.refetch()
  }, [deleteSectionState.loading])

  return (
    <MainCard content={false}>
      {(sections.loading || deleteSectionState.loading) && <Loader />}

      <CreateLoanDocumentSectionSection onClose={() => setCreateModalOpen(false)}
                                        open={createModalOpen}
                                        refetchList={() => sections.refetch()} />
      {updateSectionItem && <UpdateLoanDocumentSectionSection
        onClose={() => setUpdateSectionItem(undefined)}
        open={true}
        section={updateSectionItem}
      />}
      <Stack spacing={3}>
        <Stack
          direction='row'
          spacing={1}
          justifyContent='flex-end'
          alignItems='center'
          sx={{ p: 3, pb: 0 }}
        >
          <HasPermissions permission='loan-document-sections.create'>
            <Button variant='contained'
                    startIcon={<ReloadOutlined />}
                    size='small'
                    onClick={() => setCreateModalOpen(true)}>
              Create New
            </Button>
          </HasPermissions>
        </Stack>
        <ReactTable<ArrayElement<GetLoanDocumentSections['loanDocumentSections']>>
          columns={[
            { Header: 'Name', accessor: 'name' },
            { Header: 'LoanPro ID', accessor: 'loanProId' },
            {
              Header: 'Actions',
              accessor: row =>
                <Stack direction='row' spacing={2} useFlexGap>
                  <HasPermissions permission='loan-document-sections.update'>
                    <IconButton
                      color='primary'
                      onClick={() => setUpdateSectionItem(row)}
                    >
                      <EditOutlined />
                    </IconButton>
                  </HasPermissions>
                  <HasPermissions permission='loan-document-sections.delete'>
                    <IconButton
                      color='error'
                      onClick={() => deleteHandler(row.id, row.name)}
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </HasPermissions>
                </Stack>,
              width: 30,
            },
          ]}
          data={sections.data?.loanDocumentSections ?? []}
        />
      </Stack>
    </MainCard>
  )
}
