import { Redirect, useLocation } from 'react-router-dom'

// project import
import useAuth from 'hooks/useAuth'

// types
import { GuardProps } from 'types/auth'
import React from 'react'

// ==============================|| AUTH GUARD ||============================== //

type Props = GuardProps & {
  permission?: string
}

export const AuthGuard: React.FC<Props> = ({ children, saveRedirect = true, permission }) => {
  const { isLoggedIn, checkPermission } = useAuth()
  const location = useLocation()

  if (isLoggedIn) {
    if (permission) {
      if (checkPermission(permission)) return <>{children}</>
      return <Redirect to={'/'} />
    } else {
      return <>{children}</>
    }
  }

  if (saveRedirect) localStorage.setItem('login_redirect', location.pathname)

  return <Redirect to={'/login'} />
}
