// project import
// types
import { NavItemType } from 'types/menu'
import { MyTasksChip } from 'menu-items/chips/my-tasks.chip'

// ==============================|| MENU ITEMS ||============================== //

export const menuItems: { items: NavItemType[] } = {
  items: [
    {
      id: 'lms',
      title: 'LMS',
      type: 'group',
      children: [
        {
          id: 'loans',
          title: 'Loans',
          type: 'item',
          url: '/loans',
          permission: 'loans.view'
        },
        {
          id: 'my-tasks',
          title: 'My Tasks',
          smartChip: <MyTasksChip />,
          type: 'item',
          url: '/my-tasks',
          permission: 'loans.tasks.view',
        },
      ],
    },
    {
      id: 'system',
      title: 'System',
      type: 'group',
      children: [
        {
          id: 'admins',
          title: 'Admins',
          type: 'item',
          url: '/admins',
          permission: 'admins.view'
        },
        {
          id: 'roles',
          title: 'Roles',
          type: 'item',
          url: '/roles',
          permission: 'roles.view'
        },
        {
          id: 'task-automations',
          title: 'Loan Tasks Automations',
          type: 'item',
          url: '/tasks-automations',
          permission: 'loans.tasks-automations.view'
        },
        {
          id: 'loan-document-sections',
          title: 'Loan Document Sections',
          type: 'item',
          url: '/loan-document-sections',
          permission: 'loan-document-sections.view'
        },
      ],
    },
  ],
}
