import React, { useState } from 'react'

// material-ui
import { Button, Grid, Stack, Typography } from '@mui/material'

// third-party
// project import
import AnimateButton from 'components/@extended/AnimateButton'
import { AuthWrapper } from 'sections/auth/auth-wrapper'
import { useMutation } from '@apollo/client'
import { Login2FAMutation } from 'sections/auth/auth-forms/queries'
import { Login2FA, Login2FAVariables } from 'sections/auth/auth-forms/__generated__/Login2FA'
import useAuth from 'hooks/useAuth'
import toast from 'utils/ToastNotistack'
import { CodeInput } from 'components/code-input'

type Props = {
  token: string
}

const AuthCodeVerification: React.FC<Props> = ({ token }) => {
  const auth = useAuth()
  const [otp, setOtp] = useState<string>()
  const [otpError, setOtpError] = useState<boolean>(false)

  const [login2FA, login2FAState] = useMutation<Login2FA, Login2FAVariables>(Login2FAMutation)

  React.useEffect(() => {
    if (login2FAState.error) {
      setOtpError(true)
      toast(
        login2FAState.error.message,
        { variant: 'error' },
      )
    }

    if (login2FAState.data) {
      const data = login2FAState.data.loginCode
      auth.login(
        data.accessToken,
        {
          id: data.user.id,
          name: data.user.name,
          permissions: data.user.permissions,
          email: data.user.email,
        },
      )
      return
    }
  }, [login2FAState.loading])

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant='h3'>Enter Verification Code</Typography>
            <Typography color='secondary'>We send you text message.</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CodeInput
                fieldsCount={6}
                onChange={
                  (newCode: string, isComplete) => {
                    if (isComplete) {
                      setOtp(newCode)
                      login2FA({ variables: { token: token, code: newCode } })
                    } else {
                      setOtp(undefined)
                      setOtpError(false)
                    }
                  }
                }
                hasError={otpError}
              />
            </Grid>
            <Grid item xs={12}>
              <AnimateButton>
                <Button disableElevation
                        fullWidth
                        size='large'
                        type='submit'
                        variant='contained'
                        disabled={login2FAState.loading || !otp?.length}
                        onClick={() => {
                          if (otp?.length != 6) {
                            setOtpError(true)
                          } else {
                            login2FA({ variables: { token: token, code: otp } })
                          }
                        }}
                >
                  Continue
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthWrapper>
  )
}

export default AuthCodeVerification
