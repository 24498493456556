import React, { useRef, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'

// project import
import { MainCard } from 'components/MainCard'
import IconButton from 'components/@extended/IconButton'
import Transitions from 'components/@extended/Transitions'

// assets
import { BellOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { GetLatestNotifications } from 'layout/main-layout/header/header-content/__generated__/GetLatestNotifications'
import { GetLatestNotificationsQuery } from 'layout/main-layout/header/header-content/queries'
import { MarkAllNotificationsAsReadMutation, MarkNotificationAsReadMutation } from 'pages/notifications/queries'
import { MarkAllNotificationsAsRead } from 'pages/notifications/__generated__/MarkAllNotificationsAsRead'
import {
  MarkNotificationAsRead,
  MarkNotificationAsReadVariables,
} from 'pages/notifications/__generated__/MarkNotificationAsRead'
import Loader from 'components/Loader'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { GenerateLink } from 'pages/notifications/utils/generate-link'
import useAuth from 'hooks/useAuth'
import { MeQuery } from 'contexts/queries'

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem',
}

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none',
}

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const theme = useTheme()
  const auth = useAuth()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'))
  const history = useHistory()

  const anchorRef = useRef<any>(null)
  const [open, setOpen] = useState(false)

  const notificationsState = useQuery<GetLatestNotifications>(GetLatestNotificationsQuery)
  const [markAllAsRead, markAllAsReadState] = useMutation<MarkAllNotificationsAsRead, MarkNotificationAsReadVariables>(MarkAllNotificationsAsReadMutation)
  const [markAsRead] = useMutation<MarkNotificationAsRead, MarkNotificationAsReadVariables>(MarkNotificationAsReadMutation, { refetchQueries: [{ query: MeQuery }] })

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300'
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100'

  const edges = [...(notificationsState.data?.notifications.edges ?? [])].reverse()

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      {markAllAsReadState.loading && <Loader />}
      <IconButton
        color='secondary'
        variant='light'
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label='open profile'
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <Badge badgeContent={auth.notificationsCount} color='primary'>
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type='grow'
                       position={matchesXs ? 'top' : 'top-right'}
                       sx={{ overflow: 'hidden' }}
                       in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 285,
                },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title='Notification'
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {(
                        <Tooltip title='Mark as all read'>
                          <IconButton color='success' size='small' onClick={() => markAllAsRead()}>
                            <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <List
                    component='nav'
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' },
                      },
                    }}
                  >
                    {edges?.map(i => (
                      <React.Fragment key={i.node.id}>
                        <ListItemButton selected={!i.node.readAt}
                                        key={i.node.id}
                                        onClick={() => {
                                          markAsRead({ variables: { id: i.node.id } })
                                          const link = GenerateLink(i.node)
                                          if (link) history.push(GenerateLink(i.node))
                                        }}>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                color: 'success.main',
                                bgcolor: 'success.lighter',
                              }}
                            >
                              <BellOutlined />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography variant='h6' dangerouslySetInnerHTML={{__html: i.node.title}} />
                            }
                            secondary={dayjs(i.node.createdAt).fromNow()}
                          />
                          <ListItemSecondaryAction>
                            <Typography variant='caption' noWrap>
                              {dayjs(i.node.createdAt).format('LT')}
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                      </React.Fragment>
                    ))}
                    <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }}
                                    onClick={() => history.push('/notifications')}>
                      <ListItemText
                        primary={
                          <Typography variant='h6' color='primary'>
                            View All
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}

export default Notification
