import { gql } from '@apollo/client'
import { NotificationFragment } from 'pages/notifications/fragments'

export const GetNotificationsQuery = gql`
    ${NotificationFragment}
    query GetNotifications($cursor: String) {
        notifications(pagination: {before: $cursor, last: 10}) {
            edges {
                node {
                    ...NotificationFragment
                }
            }
            pageInfo {
                startCursor
                hasPreviousPage
            }
        }
    }
`

export const MarkNotificationAsReadMutation = gql`
    mutation MarkNotificationAsRead($id: UUID!) {
        markNotificationsAsRead(notificationsIds: [$id]) {
            id
            title
            readAt
            createdAt
        }
    }
`
export const MarkAllNotificationsAsReadMutation = gql`
    mutation MarkAllNotificationsAsRead {
        markAllNotificationsAsRead
    }
`
