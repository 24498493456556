import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'
import { Login } from 'pages/auth/login'
import { Error404 } from 'pages/maintenance/error-404'
import { SmartRoute } from 'routes/helpers/smart-route'
import Loader from 'components/Loader'
import { LoanDocumentSections } from 'pages/loan-document-sections'

const Notifications = React.lazy(() => import("pages/notifications").then(module => ({ default: module.Notifications })));
const Admins = React.lazy(() => import("pages/admins").then(module => ({ default: module.Admins })));
const Roles = React.lazy(() => import("pages/roles").then(module => ({ default: module.Roles })));
const Loans = React.lazy(() => import("pages/loans").then(module => ({ default: module.Loans })));
const MyTasks = React.lazy(() => import("pages/my-tasks").then(module => ({ default: module.MyTasks })));
const TaskAutomations = React.lazy(() => import("pages/tasks-automations").then(module => ({ default: module.TaskAutomations })));
const Search = React.lazy(() => import("pages/search").then(module => ({ default: module.Search })));

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact
             path='/'
             children={<Redirect to='/loans' />}
      />
      <SmartRoute
        exact
        path='/login'
        layout='common'
        guestsOnly
        children={<Login />}
      />
      <SmartRoute
        path='/notifications'
        authOnly
        layout='main'
        children={<React.Suspense fallback={<Loader />}><Notifications /></React.Suspense>}
      />
      <SmartRoute
        path='/admins'
        authOnly
        permission='admins.view'
        children={<React.Suspense fallback={<Loader />}><Admins /></React.Suspense>}
      />
      <SmartRoute
        path='/roles'
        authOnly
        permission='roles.view'
        children={<React.Suspense fallback={<Loader />}><Roles /></React.Suspense>}
      />
      <SmartRoute
        path='/loans'
        authOnly
        permission='loans.view'
        children={<React.Suspense fallback={<Loader />}><Loans /></React.Suspense>}
      />
      <SmartRoute
        path='/my-tasks'
        authOnly
        layout='main'
        permission='loans.tasks.view'
        children={<React.Suspense fallback={<Loader />}><MyTasks /></React.Suspense>}
      />
      <SmartRoute
        path='/tasks-automations'
        authOnly
        permission='loans.tasks-automations.view'
        children={<React.Suspense fallback={<Loader />}><TaskAutomations /></React.Suspense>}
      />
      <SmartRoute
        path='/loan-document-sections'
        authOnly
        layout='main'
        permission='loan-document-sections.view'
        children={<React.Suspense fallback={<Loader />}><LoanDocumentSections /></React.Suspense>}
      />
      <SmartRoute
        path='/search'
        authOnly
        layout='main'
        children={<React.Suspense fallback={<Loader />}><Search /></React.Suspense>}
      />

      <SmartRoute
        path='*'
        layout='common'
        children={<Error404 />}
      />
    </Switch>
  )
}
