import * as React from 'react'
import { Box, Button, InputAdornment, OutlinedInput, Stack } from '@mui/material'
import { SearchOutlined } from '@ant-design/icons'
import { SearchResultsSection } from 'layout/main-layout/header/header-content/search/sections/search-results.section'
import { useLazyQuery } from '@apollo/client'
import { SearchQuery } from 'layout/main-layout/header/header-content/search/queries'
import {
  Search as SearchGQL,
  SearchVariables,
} from 'layout/main-layout/header/header-content/search/__generated__/Search'
import { debounce, delay } from 'lodash'
import { Link } from 'react-router-dom'


type Props = {};
export const Search: React.FC<Props> = props => {
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [focused, setFocused] = React.useState(false)
  const [result, setResult] = React.useState<SearchGQL | null>(null)
  const [hasError, setHasError] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const [search] = useLazyQuery<SearchGQL, SearchVariables>(SearchQuery, {
    onCompleted: data => {
      setResult(data)
      setHasError(false)
      setIsLoading(false)
    },
    onError: error => {
      setHasError(true)
      setResult(null)
      setIsLoading(false)
    },
  })

  const searchDebounce = React.useMemo(
    () =>
      debounce((keyword: string) => {
        if (keyword.length) {
          search({ variables: { keyword: keyword }, fetchPolicy: 'no-cache' })
        } else {
          setIsLoading(false)
        }
      }, 1000),
    [],
  )

  React.useEffect(() => {
    if (!searchKeyword.length && result) {
      setResult(null)
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    searchDebounce(searchKeyword)
  }, [searchKeyword])

  React.useEffect(() => {
    return () => {
      searchDebounce.cancel()
    }
  }, [searchDebounce])

  return (
    <Box sx={{ width: '100%', maxWidth: 600 }} style={{ position: 'relative' }}>
      <Stack direction='row' gap={1} useFlexGap>
        <OutlinedInput
          size='small'
          id='header-search'
          startAdornment={
            <InputAdornment position='start' sx={{ mr: -0.5 }}>
              <SearchOutlined />
            </InputAdornment>
          }
          aria-describedby='header-search-text'
          inputProps={{
            'aria-label': 'weight',
          }}
          placeholder='Search...'
          sx={{ flex: 1 }}
          value={searchKeyword}
          onChange={e => setSearchKeyword(e.currentTarget.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => delay(() => setFocused(false), 100)}
        />
        <Button color='secondary' component={Link} to='/search/'>Advanced Search</Button>
      </Stack>
      {focused && searchKeyword.length > 0 &&
        <SearchResultsSection isLoading={isLoading}
                              results={result?.search}
                              hasError={hasError}
                              keyword={searchKeyword} />}
    </Box>
  )
}
