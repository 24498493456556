import { GetLatestNotifications } from 'layout/main-layout/header/header-content/__generated__/GetLatestNotifications'
import { ArrayElement } from 'utils/array-element'

export const GenerateLink = (input: ArrayElement<GetLatestNotifications['notifications']['edges']>['node']) => {
  if (input.loan) return `/loans/${input.loan.id}`
  if (input.loanTask) return `/loans/${input.loanTask.loan.id}/tasks/${input.loanTask.id}`
  if (input.loanTaskComment) return `/loans/${input.loanTaskComment.loanTask.loan.id}/tasks/${input.loanTaskComment.loanTask.id}`

  return null
}
