import React, { CSSProperties } from 'react'
import { Link } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Divider, Grid, Typography } from '@mui/material'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'

// project import
import { MainCard } from 'components/MainCard'

// assets
import { HomeFilled, HomeOutlined } from '@ant-design/icons'

// types
import { OverrideIcon } from 'types/root'
import { LayoutContext } from 'contexts/layout.context'

// ==============================|| BREADCRUMBS ||============================== //

export interface BreadCrumbSxProps extends CSSProperties {
  mb?: string;
  bgcolor?: string;
}

interface Props {
  card?: boolean;
  divider?: boolean;
  icon?: boolean;
  icons?: boolean;
  maxItems?: number;
  rightAlign?: boolean;
  separator?: OverrideIcon;
  title?: boolean;
  titleBottom?: boolean;
  sx?: BreadCrumbSxProps;
}

export const Breadcrumbs: React.FC<Props> = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}) => {
  const theme = useTheme()
  const layoutProps = React.useContext(LayoutContext)

  const iconSX = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: '1rem',
    height: '1rem',
    color: theme.palette.secondary.main,
  }

  // item separator
  const SeparatorIcon = separator!
  const separatorIcon = separator ? <SeparatorIcon style={{ fontSize: '0.75rem', marginTop: 2 }} /> : '/'


  return <MainCard
    border={card}
    sx={card === false ? { mb: 3, bgcolor: 'transparent', ...sx } : { mb: 3, ...sx }}
    {...others}
    content={card}
    shadow='none'
    divider={false}
  >
    <Grid
      container
      direction={rightAlign ? 'row' : 'column'}
      justifyContent={rightAlign ? 'space-between' : 'flex-start'}
      alignItems={rightAlign ? 'center' : 'flex-start'}
      spacing={1}
    >
      {layoutProps.pageTitle && !titleBottom && (
        <Grid item>
          <Typography variant='h2'>{layoutProps.pageTitle}</Typography>
        </Grid>
      )}
      <Grid item>
        {layoutProps.breadcrumbs &&
          <MuiBreadcrumbs aria-label='breadcrumb' maxItems={maxItems || 8} separator={separatorIcon}>
            <Typography component={Link} to='/' color='textSecondary' variant='h6' sx={{ textDecoration: 'none' }}>
              {icons && <HomeOutlined style={iconSX} />}
              {icon && !icons && <HomeFilled style={{ ...iconSX, marginRight: 0 }} />}
              {(!icon || icons) && 'Home'}
            </Typography>
            {layoutProps.breadcrumbs?.map((item, k) => {
              const isLast = layoutProps.breadcrumbs!.length - 1 == k
              const color = isLast ? 'textPrimary' : 'textSecondary'
              if (item.link) {
                return (
                  <Typography key={k}
                              variant='subtitle1'
                              color={color}
                              component={Link}
                              to={item.link}
                              sx={{ textDecoration: 'none' }}>
                    {item.title}
                  </Typography>
                )
              } else if (item.href) {
                return (
                  <Typography key={k}
                              variant='subtitle1'
                              color={color}
                              component={'a'}
                              href={item.href}
                              sx={{ textDecoration: 'none' }}>
                    {item.title}
                  </Typography>
                )
              } else {
                return (
                  <Typography key={k}
                              variant='subtitle1'
                              color={color}
                              sx={{ textDecoration: 'none' }}>
                    {item.title}
                  </Typography>
                )
              }
            })}
          </MuiBreadcrumbs>
        }
      </Grid>
      {layoutProps.pageTitle && titleBottom && (
        <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
          <Typography variant='h2'>{layoutProps.pageTitle}</Typography>
        </Grid>
      )}
    </Grid>
    {card === false && divider && <Divider sx={{ mt: 2 }} />}
  </MainCard>
}
