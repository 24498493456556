import * as React from 'react'
import { MainCard } from 'components/MainCard'
import { Alert, Button, Card, Divider, ListItemButton, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { CaretRightFilled } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Search_search } from 'layout/main-layout/header/header-content/search/__generated__/Search'
import { useHistory } from 'react-router-dom'

type Props = {
  isLoading?: boolean
  hasError?: boolean
  results?: Search_search
  keyword: string
};


const ResultsContainer = styled.div`
  position: absolute;
  top: 2.4em;
  left: 0;
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
`

export const SearchResultsSection: React.FC<Props> = props => {
  const history = useHistory()
  const totalRecords = props.results?.comments?.totalRecords ?? 0
  return (
    <ResultsContainer>
      <MainCard isLoading={props.isLoading} content={false}>
        <Stack direction='column' sx={{ px: 2, py: 1, pb: 2 }}>
          {(props.results?.comments?.totalRecords ?? 0) > 0 && <>
            <Typography variant='subtitle2' sx={{ pb: 1 }}>Comments</Typography>
            <Stack direction='column' spacing={1} useFlexGap>
              {props.results?.comments?.edges.map(comment => (
                <Card elevation={2} key={comment.id}>
                  <ListItemButton
                    sx={{ p: 1, display: 'block' }}
                    onClick={() => history.push(`/loans/${comment.loanTask.loan.id}/tasks/${comment.loanTask.id}`)}
                  >
                    <Typography variant='subtitle2' color={grey['600']}>
                      {comment.loanTask.loan.customerName} <CaretRightFilled /> {comment.loanTask.loan.name}
                    </Typography>
                    <Typography variant='subtitle2'>
                      {comment.loanTask.title}
                    </Typography>
                    <Divider sx={{ my: .5 }} />
                    <Typography variant='subtitle2'>
                      {comment.commentText}
                    </Typography>
                  </ListItemButton>
                </Card>
              ))}
            </Stack>
            {(props.results?.comments?.totalRecords ?? 0) > 3 &&
              <Button sx={{ mt: 1 }}
                      onClick={() => history.push(`/search/?keyword=${encodeURI(props.keyword)}#comments`)}>Show more
                comments ({props.results?.comments?.totalRecords} total)</Button>}
          </>}
          {totalRecords == 0 && !props.isLoading && !props.hasError &&
            <Typography variant='h5' sx={{ mt: 1, textAlign: 'center' }}>Nothing found</Typography>}
          {props.hasError &&
            <Alert severity='error' sx={{ mt: 1, textAlign: 'center' }}>Error while pulling search</Alert>}
        </Stack>
      </MainCard>
    </ResultsContainer>
  )
}
