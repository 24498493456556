import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// third-party
import { Provider as ReduxProvider } from 'react-redux'
import dayjs from 'dayjs'
import relative from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'

// scroll bar
import 'simplebar/src/simplebar.css'

// apex-chart
import 'assets/third-party/apex-chart.css'
import 'assets/third-party/react-table.css'

// project import
import App from 'app'
import { store } from 'store'
import { ConfigProvider } from 'contexts/config.context'
import reportWebVitals from './reportWebVitals'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { QueryClient, QueryClientProvider } from 'react-query'

dayjs.extend(relative)
dayjs.extend(localizedFormat)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const container = document.getElementById('root')
const root = createRoot(container!)

const queryClient = new QueryClient()

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <ReduxProvider store={store}>
    <ConfigProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </QueryClientProvider>
      </LocalizationProvider>
    </ConfigProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
