import * as React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { SmartTextField } from 'components/smart-inputs/smart-text-field'
import { useMutation } from '@apollo/client'
import toast from 'utils/ToastNotistack'
import { GetLoanDocumentSectionsQuery, UpdateLoanDocumentSectionMutation } from 'pages/loan-document-sections/queries'
import { ArrayElement } from 'utils/array-element'
import { GetLoanDocumentSections } from 'pages/loan-document-sections/__generated__/GetLoanDocumentSections'
import {
  UpdateLoanDocumentSection,
  UpdateLoanDocumentSectionVariables,
} from 'pages/loan-document-sections/__generated__/UpdateLoanDocumentSection'

type Props = {
  section: ArrayElement<GetLoanDocumentSections['loanDocumentSections']>
  open: boolean
  onClose: () => void
};
export const UpdateLoanDocumentSectionSection: React.FC<Props> = props => {
  const [updateSection, updateSectionState] = useMutation<UpdateLoanDocumentSection, UpdateLoanDocumentSectionVariables>(UpdateLoanDocumentSectionMutation)

  const formikRef = React.useRef<FormikProps<{
    name: string,
    loanProId: string
  }>>(null)

  React.useEffect(() => {
    if (!updateSectionState.loading && formikRef.current?.isSubmitting) formikRef.current.setSubmitting(false)
    if (updateSectionState.error) {
      toast(
        updateSectionState.error.message,
        { variant: 'error' },
      )
    }
    if (updateSectionState.data) {
      toast(
        `Section ${updateSectionState.data.updateLoanDocumentSection.name} updated`,
        { variant: 'success' },
      )
      formikRef.current?.resetForm()
      props.onClose()
    }
  }, [updateSectionState.loading])

  return (
    <Dialog open={props.open}
            onClose={() => !updateSectionState.loading && props.onClose()}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
      <Formik
        innerRef={formikRef}
        initialValues={{ name: props.section.name, loanProId: String(props.section.loanProId) }}
        onSubmit={values => {
          updateSection({
            variables: {
              id: props.section.id,
              input: {
                name: values.name,
                loanProId: Number(values.loanProId),
              },
            },
            refetchQueries: [
              { query: GetLoanDocumentSectionsQuery },
            ],
          })
        }}
        validationSchema={
          Yup.object().shape({
            name: Yup.string().required(),
            loanProId: Yup.string().required().test('Digits only', 'The field should have digits only', (value) => /^\d+$/.test(value)),
          })
        }
      >
        {form => (
          <Box sx={{ p: 1, py: 1.5, minWidth: 400 }}>
            <DialogTitle id='alert-dialog-title'>Update Loan Document Section</DialogTitle>
            <DialogContent>
              <Stack direction='column' spacing={2}>
                <SmartTextField field='name' label='Name' />
                <SmartTextField field='loanProId' label='LoanPro ID' />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => props.onClose()}
                disabled={updateSectionState.loading}
              >
                Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={form.submitForm}
                disabled={updateSectionState.loading}
              >
                Save Section
              </Button>
            </DialogActions>
          </Box>
        )}
      </Formik>
    </Dialog>
  )
}
