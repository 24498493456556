import { gql } from '@apollo/client'

export const LoginMutation = gql`
    mutation Login($email: String!, $password: String!) {
        login(input: {email: $email, password: $password}) {
            codeToken
        }
    }
`
export const Login2FAMutation = gql`
    mutation Login2FA($token: String!, $code: String!) {
        loginCode(input: {token: $token, code: $code}) {
            accessToken
            user {
                id
                name
                permissions
                email
            }
        }
    }
`
