import { gql } from '@apollo/client'

export const MeQuery = gql`
    query Me {
        me {
            myTasksCount
            notificationsCount
            user {
                id
                name
                permissions
                email
            }
        }
    }
`
