import { gql } from '@apollo/client'

export const GetLoanDocumentSectionsQuery = gql`
    query GetLoanDocumentSections {
        loanDocumentSections {
            id
            name
            loanProId
        }
    }
`

export const CreateLoanDocumentSectionMutation = gql`
    mutation CreateLoanDocumentSection($input: LoanDocumentSectionInput!) {
        createLoanDocumentSection(input: $input) {
            id
            name
            loanProId
        }
    }
`

export const UpdateLoanDocumentSectionMutation = gql`
    mutation UpdateLoanDocumentSection($id: UUID!, $input: LoanDocumentSectionInput!) {
        updateLoanDocumentSection(id: $id, input: $input) {
            id
            name
            loanProId
        }
    }
`

export const DeleteLoanDocumentSectionMutation = gql`
    mutation DeleteLoanDocumentSection($id: UUID!) {
        deleteLoanDocumentSection(id: $id)
    }
`
