// project import
import { Routes } from 'routes'
import ThemeCustomization from 'themes'
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop'
import Snackbar from 'components/@extended/Snackbar'
import Notistack from 'components/third-party/Notistack'

// auth-provider
import { AuthContextComponent } from 'contexts/auth.context'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from 'services/apollo'

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <ThemeCustomization>
    <ScrollTop>
      <ApolloProvider client={apolloClient}>
        <AuthContextComponent>
          <>
            <Notistack>
              <Routes />
              <Snackbar />
            </Notistack>
          </>
        </AuthContextComponent>
      </ApolloProvider>
    </ScrollTop>
  </ThemeCustomization>
)

export default App
