import { Redirect, useLocation } from 'react-router-dom'

// project import
import { APP_DEFAULT_PATH } from 'config'
import useAuth from 'hooks/useAuth'

// types
import { GuardProps } from 'types/auth'
import React from 'react'

// ==============================|| GUEST GUARD ||============================== //

export const GuestGuard: React.FC<GuardProps> = ({ children }) => {
  const { isLoggedIn } = useAuth()
  const location = useLocation()

  if (isLoggedIn) {
    localStorage.removeItem('login_redirect')
    return <Redirect to={location?.state?.from ?? APP_DEFAULT_PATH} />
  }

  return <>{children}</>
}
