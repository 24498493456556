import { gql } from '@apollo/client'

export const NotificationFragment = gql`
    fragment NotificationFragment on Notification {
        id
        title
        readAt
        createdAt
        loan {
            id
            name
        }
        loanTask {
            id
            title
            loan {
                id
            }
        }
        loanTaskComment {
            id
            loanTask {
                id
                title
                loan {
                    id
                }
            }
        }
    }
`
