import React, { createContext, ReactNode, useState } from 'react'
import { Helmet } from 'react-helmet'

type Breadcrumb = {
  title: string
  link?: string
  href?: string
}

type PropVariables = {
  pageTitle?: string
  breadcrumbs?: Breadcrumb[]
}

type Props = PropVariables & {
  setPageTitle: (title: string) => void
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
  setPageTitleAndBreadcrumbs: (title: string, breadcrumbs: Breadcrumb[]) => void
  reset: () => void
}

// initial state
const initialState: Props = {
  setPageTitle: () => {
  },
  setBreadcrumbs: () => {
  },
  setPageTitleAndBreadcrumbs: () => {
  },
  reset: () => {
  },
}

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

export const LayoutContext = createContext(initialState)

type LayoutProviderProps = {
  children: ReactNode;
};

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<PropVariables>()

  const setPageTitle = (title: string) => {
    setConfig({
      ...config,
      pageTitle: title,
    })
  }
  const setBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
    setConfig({
      ...config,
      breadcrumbs: breadcrumbs,
    })
  }
  const setPageTitleAndBreadcrumbs = (title: string, breadcrumbs: Breadcrumb[]) => {
    setConfig({
      ...config,
      pageTitle: title,
      breadcrumbs: breadcrumbs,
    })
  }

  return (
    <LayoutContext.Provider
      value={{
        ...config,
        setPageTitle,
        setBreadcrumbs,
        setPageTitleAndBreadcrumbs,
        reset: () => setConfig({}),
      }}
    >
      <Helmet>
        <title>BLSQ Intranet{config?.pageTitle ? ` - ${config?.pageTitle}` : ''}</title>
      </Helmet>
      {children}
    </LayoutContext.Provider>
  )
}

export const configPage = (title: string, breadcrumbs: Breadcrumb[]) => {
  const layout = React.useContext(LayoutContext)

  React.useEffect(() => {
    layout.setPageTitleAndBreadcrumbs(title, breadcrumbs)
    return () => layout.reset()
  }, [])
}
