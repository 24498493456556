import * as React from 'react'
import { SetCharAt } from 'utils/set-char-at'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import { TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'

type Props = {
  fieldsCount: number
  onChange: (value: string, isComplete: boolean) => void
  focusOnRender?: boolean
  isDisabled?: boolean
  inputRegEx?: RegExp
  inputPattern?: string
  hasError?: boolean
  digitInputProps?: TextFieldProps
};

const ShakingKeyFrames = keyframes`
  0% {
    transform: translate(1rem);
  }
  20% {
    transform: translate(-1rem);
  }
  40% {
    transform: translate(.5rem);
  }
  60% {
    transform: translate(-.5rem);
  }
  80% {
    transform: translate(.25rem);
  }
  100% {
    transform: translate(0);
  }
`

const InputsContainer = styled.div<{ withError?: boolean, fieldsCount: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.fieldsCount}, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: .5rem;

  animation: ${props => props.withError ? css`${ShakingKeyFrames} 400ms 1 linear` : 'none'};
`

export const CodeInput: React.FC<Props> = props => {
  const {
    fieldsCount,
    onChange,
    focusOnRender,
    isDisabled = false,
    inputRegEx = /^\d+$/,
    inputPattern = '\d*',
    hasError = false,
    digitInputProps,
    ...otherProps
  } = props

  const [code, setCode] = React.useState(' '.repeat(fieldsCount))

  React.useEffect(() => {
    if (focusOnRender) document.getElementById(`verification_code_0`)?.focus()
  }, [])

  const setCodeDigit = React.useMemo(() => (key: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.isDisabled) {
      event.preventDefault()
      event.stopPropagation()

      return
    }
    const newValue = event.target.value.trim()

    if (newValue.length == 0) {
      setCode(SetCharAt(code, key, ' '))
      return
    }

    if (!inputRegEx.test(newValue)) {
      event.preventDefault()
      event.stopPropagation()

      setCode(SetCharAt(code, key, ' '))
      return
    }

    const newCode = SetCharAt(code, key, newValue.charAt(0))
    const isComplete = inputRegEx.test(newCode)
    setCode(newCode)
    onChange(newCode, isComplete)

    if (key != fieldsCount - 1) {
      document.getElementById(`verification_code_${key + 1}`)?.focus()
    }
  }, [code])

  const onKeyDown = React.useMemo(() => (key: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key == 'Backspace') {
      event.preventDefault()
      event.stopPropagation()

      if (code.at(key) != ' ') {
        const newCode = SetCharAt(code, key, ' ')
        setCode(newCode)
        onChange(newCode, false)
      }
      if (key != 0) {
        document.getElementById(`verification_code_${key - 1}`)?.focus()
      }
    }
  }, [code])

  const onPaste = React.useMemo(() => (key: number) => (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()

    const pasted = event.clipboardData.getData('text/plain').replace(/\D/g, '')
    if (!pasted.length) return

    if (pasted.length == fieldsCount) {
      setCode(pasted)
      document.getElementById(`verification_code_${fieldsCount - 1}`)?.focus()
      onChange(pasted, true)
      return
    }

    let newCode = code
    let itemPos = 0
    for (let k = key; key < fieldsCount; k++) {
      const newChar = pasted.at(itemPos)
      if (!newChar) break
      newCode = SetCharAt(newCode, k, newChar)
      itemPos++
    }
    document.getElementById(`verification_code_${key + itemPos - 1}`)?.focus()

    setCode(newCode)
    onChange(pasted, inputRegEx.test(newCode))
  }, [code])

  return (
    <InputsContainer withError={hasError} fieldsCount={fieldsCount} {...otherProps}>
      {code.split('').map((v, k) => (
        <TextField
          id={`verification_code_${k}`}
          autoComplete='none'
          value={v == ' ' ? '' : v}
          key={k}
          onChange={setCodeDigit(k)}
          onKeyDown={onKeyDown(k)}
          onPaste={onPaste(k)}
          disabled={isDisabled}
          {...digitInputProps}
          inputProps={{
            size: 1,
            readOnly: isDisabled,
            pattern: inputPattern,
            ...digitInputProps?.inputProps,
            style: {
              textAlign: 'center',
              fontSize: '1.5em',
              ...digitInputProps?.inputProps?.style,
            },
          }}
        />
      ))}
    </InputsContainer>
  )
}
