import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { AuthGuard } from 'utils/route-guard/auth.guard'
import { CommonLayout } from 'layout/common-layout'
import { MainLayout } from 'layout/main-layout'
import { GuestGuard } from 'utils/route-guard/guest.guard'

type Props = RouteProps & {
  children: React.ReactNode
  layout?: 'common' | 'main'
  guestsOnly?: boolean
  authOnly?: boolean
  permission?: string
}

export const SmartRoute: React.FC<Props> = ({
  children,
  layout = 'common',
  authOnly = false,
  guestsOnly = false,
  permission,
  ...props
}) => {
  let build = children

  if (layout) {
    switch (layout) {
      case 'common':
        build = <CommonLayout>{build}</CommonLayout>
        break
      case 'main':
        build = <MainLayout>{build}</MainLayout>
        break
    }
  }

  if (authOnly) {
    build = <AuthGuard permission={permission}>{build}</AuthGuard>
  } else if (guestsOnly) {
    build = <GuestGuard>{build}</GuestGuard>
  }

  return (
    <Route {...props}>
      {build}
    </Route>
  )
}
