import { gql } from '@apollo/client'

export const GetLatestNotificationsQuery = gql`
    query GetLatestNotifications {
        notifications (pagination: {last: 5}){
            edges {
                node {
                    id
                    title
                    readAt
                    createdAt
                    loan {
                        id
                        name
                    }
                    loanTask {
                        id
                        title
                        loan {
                            id
                        }
                    }
                    loanTaskComment {
                        id
                        loanTask {
                            id
                            title
                            loan {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`
