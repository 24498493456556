import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Container, Toolbar, useMediaQuery } from '@mui/material'

// project import
import Drawer from './drawer'
import Header from './header'
import Footer from 'layout/main-layout/footer'
import HorizontalBar from 'layout/main-layout/drawer/horizontal-bar'
import { Breadcrumbs } from 'components/@extended/breadcrumbs'
import useConfig from 'hooks/useConfig'
import { openDrawer } from 'store/reducers/menu'

// types
import { RootStateProps } from 'types/root'
import { LAYOUT_CONST } from 'types/config'
import { LayoutProvider } from 'contexts/layout.context'

// ==============================|| MAIN LAYOUT ||============================== //

type Props = {
  children: React.ReactNode
}

export const MainLayout: React.FC<Props> = ({ children }) => {
  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'))
  const downLG = useMediaQuery(theme.breakpoints.down('lg'))

  const { container, miniDrawer, menuOrientation } = useConfig()
  const dispatch = useDispatch()

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG

  const menu = useSelector((state: RootStateProps) => state.menu)
  const { drawerOpen } = menu

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen)
  const handleDrawerToggle = () => {
    setOpen(!open)
    dispatch(openDrawer({ drawerOpen: !open }))
  }

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG)
      dispatch(openDrawer({ drawerOpen: !matchDownLG }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG])

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen])

  return (
    <LayoutProvider>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        {!isHorizontal ? <Drawer open={open} handleDrawerToggle={handleDrawerToggle} /> : <HorizontalBar />}

        <Box component='main' sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
          <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
          <Container
            maxWidth={container ? 'xl' : false}
            sx={{
              ...(container && { px: { xs: 0, sm: 2 } }),
              position: 'relative',
              minHeight: 'calc(100vh - 110px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Breadcrumbs title titleBottom card={false} divider={false} />
            {children}
            <Footer />
          </Container>
        </Box>
      </Box>
    </LayoutProvider>
  )
}
