// material-ui
import { Theme } from '@mui/material/styles'
import { Container, Stack, Typography, useMediaQuery } from '@mui/material'

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = () => {
  const matchDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Container maxWidth='xl'>
      <Stack
        direction={matchDownSM ? 'column' : 'row'}
        justifyContent={matchDownSM ? 'center' : 'space-between'}
        spacing={2}
        textAlign={matchDownSM ? 'center' : 'inherit'}
      >
        <Typography variant='subtitle2' color='secondary' component='span'>
          © BLSQ Management
        </Typography>
      </Stack>
    </Container>
  )
}

export default AuthFooter
