// material-ui
// project import
import AuthLogin from 'sections/auth/auth-forms/auth-login'
import React from 'react'
import AuthCodeVerification from 'sections/auth/auth-forms/auth-code-verification'

// ================================|| LOGIN ||================================ //

export const Login = () => {
  const [token, setToken] = React.useState<string>()

  if (token) {
    return <AuthCodeVerification token={token} />
  } else {
    return <AuthLogin proceedTo2FA={setToken} />

  }
}
