import { gql } from '@apollo/client'

export const SearchQuery = gql`
    query Search($keyword: String!) {
        search(keyword: $keyword) {
            comments(limit: 3) {
                totalRecords
                edges {
                    id
                    commentText
                    commentHtml
                    loanTask {
                        id
                        title
                        loan {
                            id
                            name
                            customerName
                        }
                    }
                }
            }
        }
    }
`
