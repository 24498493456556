import * as React from 'react'
import useAuth from 'hooks/useAuth'

type Props<T> = {
  permission: string
  children: T
  deniedComponent?: React.ReactNode | null
};
export const HasPermissions: <T>(props: Props<T>) => JSX.Element | null = (props) => {
  const auth = useAuth()
  const [allow, setAllow] = React.useState(auth.checkPermission(props.permission))

  React.useEffect(() => {
    setAllow(auth.checkPermission(props.permission))
  }, [auth.user?.permissions])


  if (!allow) return props.deniedComponent ? <>{props.deniedComponent}</> : null

  return <>{props.children}</>
}
