import { Column, HeaderGroup, useTable } from 'react-table'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

type Props<ItemType extends object, DataType = Array<ItemType>> = {
  columns: Column<ItemType>[]
  data?: DataType
  striped?: boolean
}

export function ReactTable<T extends object>(props: Props<T>) {
  const { columns, data, striped } = props
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns as Column[],
    data: data ?? [],
  })

  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup: HeaderGroup<{}>) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()} {...(striped && { className: 'striped' })}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <TableRow {...row.getRowProps()}>
              {row.cells.map(cell => (
                <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
              ))}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}
